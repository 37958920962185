export const printSizeList = [
  { value: '', id: 'free' },
  { value: 'A4_landscape', id: 'A4_landscape' },
  { value: 'B4_landscape', id: 'B4_landscape' },
  { value: 'A3_landscape', id: 'A3_landscape' },
  { value: 'A2_landscape', id: 'A2_landscape' },
  { value: 'A1_landscape', id: 'A1_landscape' },
  { value: 'A4_portrait', id: 'A4_portrait' },
  { value: 'B4_portrait', id: 'B4_portrait' },
  { value: 'A3_portrait', id: 'A3_portrait' },
  { value: 'A2_portrait', id: 'A2_portrait' },
  { value: 'A1_portrait', id: 'A1_portrait' },
]
